var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"nested-menu",style:({
    backgroundColor: _vm.backgroundColorComputed,
    fontFamily: _vm.fontFamily,
    top: _vm.topPosition,
  }),attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c('v-hover',{key:item.id,attrs:{"close-delay":"200","open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var hover = ref.hover;
return _c('v-list-item',{style:({
        backgroundColor: hover
          ? _vm.lighterColor(_vm.backgroundColorComputed)
          : _vm.backgroundColorComputed,
        borderColor: item.color,
      })},[_c('router-link',{attrs:{"to":_vm.getTo(item)}},[_c('v-list-item-title',[(item.icon)?_c('v-icon',[_vm._v(_vm._s(item.icon))]):_vm._e(),(!_vm.collapsed)?_c('div',[_vm._v(_vm._s(item.name))]):_vm._e()],1),(!_vm.collapsed && _vm.hasValidChildren(item.children))?_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()],1),(hover && _vm.hasValidChildren(item.children))?_c('NestedMenu',{attrs:{"items":item.children,"is-client":_vm.isClient,"background-color":_vm.lighterColor(_vm.backgroundColorComputed)}}):_vm._e()],1)}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }