<script>
import { CLIENTE as clientMenu } from "@/core/constants/menus";
import NestedMenu from "@/components/nested-menu.vue";

export default {
  components: {
    NestedMenu,
  },
  computed: {
    items() {
      /** @typedef {import('@/core/constants/menus').Menu} Menu
       *
       * hasPermissions
       * @param {string | string[]} target permissões exigidas
       * @param {string | string[]} permissions permissões selecionadas
       * @returns {boolean} retorna se possui todas as permissões exigidas ou não
       */
      const hasPermissions = (target, permissions) => {
        // Valida se os parametros são do tipo correto
        if ([target, permissions].some((array) => !(Array.isArray(array) || typeof array === 'string'))) {
          return false;
        }

        // Garante que os parametros serão arrays
        const [parsedTarget, parsedPermissions] = [target, permissions].map((pseudo) => Array.isArray(pseudo) ? pseudo : [pseudo]);

        // Verifica se as permissões selecionadas contem todas as permissões exigidas
        return parsedPermissions.every((perm) => parsedTarget.includes(perm));
      };

      const permissions = this.permissions;

     /**
      * @function parseMenu
      * @param {Menu} menu
      * @returns {Menu[]}
      */
      const parseMenu = (previousValue, currentValue) => {
        const { children, ...rest } = currentValue;
        const parsed = { ...rest, to: null, children: [] };

        if (Array.isArray(children)) {
          parsed.children = children.reduce(parseMenu, []);
        }

        if (hasPermissions(permissions, parsed.permissions) || parsed.children.length > 0) {
          return [...previousValue, parsed];
        }

        return previousValue;
      };

      return clientMenu.reduce(parseMenu, []);
    },
  },
  props: {
    permissions: { type: Array, default: () => [] },
  },
}
</script>

<style scoped lang="scss">
.no-data-container {
  background-color: #fff;
  padding: 0.75rem;
}

.v-list.nested-menu {
  position: absolute;
  top: calc(50% - 140px) !important;
  left: 10%;
  max-width: 30vw;
}
</style>

<template>
  <v-dialog overlay-opacity="0.8" style="position: relative">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }" />
    </template>

    <NestedMenu
      v-if="items.length"
      backgroundColor="#0B5456"
      :items="items"
      is-client
    ></NestedMenu>
    <div v-else class="no-data-container">
      <p>Nenhuma permissão selecionada</p>
    </div>
  </v-dialog>
</template>
